<template>
  <Modal v-model="overtimeOrderProcessingModal" :title="overtimeOrderProcessingTitle" width="1200" :transfer="false" :mask-closable="false"
    @on-visible-change="overtimeOrderProcessingChange">
    <h2 class="mb15">{{ overtimeOrderText }}</h2>
    <RadioGroup v-model="radioType" class="mb10" @on-change="handleData">
      <Radio label="order">{{ $t('key1000339') }}</Radio>
      <Radio label="sku">{{ $t('key1000340') }}</Radio>
    </RadioGroup>
    <Form class="table_box" ref="tableForm" :model="tableForm" @submit.native.prevent>
      <Table
        v-if="radioType === 'order'"
        highlight-row
        border
        max-height="520"
        :loading="tableLoading"
        :columns="tableColumns"
        :span-method="handleOrderSpan"
        :data="tableForm.tableData">
        <!--缺货数量-->
        <template #outOfStockQuantity="{row, index}">
          <RadioGroup v-model="tableForm.tableData[index].abnormalStatus" class="mt5 mb5" @on-change="(val)=>changeAbnormalStatus(val, index, row)">
            <Radio :label="2">{{ $t('key1000341') }}</Radio>
            <Radio :label="1">{{ $t('key1000342') }}</Radio>
          </RadioGroup>
          <FormItem
            v-if="tableForm.tableData[index].abnormalStatus === 2"
            :prop="`tableData.${index}.outOfStockQuantity`"
            :rules="[{ required: true, message: $t('key1000336')}]">
            <InputNumber
              :max="tableForm.tableData[index].quantity"
              :min="1"
              style="width: 120px"
              :formatter="value => `${parseInt(value)}`"
              v-model.trim="tableForm.tableData[index].outOfStockQuantity">
            </InputNumber>
          </FormItem>
        </template>
        <!--预计到货时间-->
        <template #expectedDeliveryTime="{row, index}">
          <template v-if="tableForm.tableData[index].abnormalStatus === 2">
            <RadioGroup v-model="tableForm.tableData[index].goodsStatus" class="mt5 mb5">
              <Radio :label="1">{{ $t('key1000343') }}</Radio>
              <Radio :label="2">{{ $t('key1000344') }}</Radio>
            </RadioGroup>
            <FormItem
              :prop="`tableData.${index}.estimateArrivalOfGoodsTime`"
              :rules="[{required: true, pattern: /.+/, message: $t('key1000337'), trigger: 'blur'}]"
              v-if="tableForm.tableData[index].goodsStatus === 1">
              <DatePicker
                type="date"
                format="yyyy-MM-dd"
                transfer
                :placeholder="$t('key1000338')"
                v-model="tableForm.tableData[index].estimateArrivalOfGoodsTime"
                style="width: 150px">
              </DatePicker>
            </FormItem>
            <h3 v-else class="font-size-12">{{ $t('key1000345') }}</h3>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </template>
      </Table>
      <Table
        v-if="radioType === 'sku'"
        highlight-row
        border
        max-height="520"
        :loading="tableLoading"
        :columns="tableSkuColumns"
        :data="tableForm.tableData">
        <!--缺货数量-->
        <template #outOfStockQuantity="{row, index}">
          <RadioGroup v-model="tableForm.tableData[index].abnormalStatus" class="mt5 mb5" @on-change="(val)=>changeAbnormalStatus(val, index, row)">
            <Radio :label="2">{{ $t('key1000341') }}</Radio>
            <Radio :label="1">{{ $t('key1000342') }}</Radio>
          </RadioGroup>
          <FormItem
            v-if="tableForm.tableData[index].abnormalStatus === 2"
            :prop="`tableData.${index}.outOfStockQuantity`"
            :rules="[{ required: true, message: $t('key1000336')}]">
            <InputNumber
              :max="tableForm.tableData[index].quantity"
              :min="1"
              style="width: 120px"
              :formatter="value => `${parseInt(value)}`"
              v-model.trim="tableForm.tableData[index].outOfStockQuantity">
            </InputNumber>
          </FormItem>
        </template>
        <!--预计到货时间-->
        <template #expectedDeliveryTime="{row, index}">
          <template v-if="tableForm.tableData[index].abnormalStatus === 2">
            <RadioGroup v-model="tableForm.tableData[index].goodsStatus" class="mt5 mb5">
              <Radio :label="1">{{ $t('key1000343') }}</Radio>
              <Radio :label="2">{{ $t('key1000344') }}</Radio>
            </RadioGroup>
            <FormItem
              :prop="`tableData.${index}.estimateArrivalOfGoodsTime`"
              :rules="[{required: true, pattern: /.+/, message: $t('key1000337'), trigger: 'blur'}]"
              v-if="tableForm.tableData[index].goodsStatus === 1">
              <DatePicker
                type="date"
                format="yyyy-MM-dd"
                transfer
                :placeholder="$t('key1000338')"
                v-model="tableForm.tableData[index].estimateArrivalOfGoodsTime"
                style="width: 150px">
              </DatePicker>
            </FormItem>
            <h3 v-else class="font-size-12">{{ $t('key1000345') }}</h3>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </template>
      </Table>
    </Form>
    <template #footer>
      <div class="flex align-items-center justify-content-between">
        <span>{{ $t('key1000346') }}</span>
        <div class="flex align-items-center">
          <Button class="mr10" @click="overtimeOrderProcessingModal = false">{{ $t('key1000347') }}</Button>
          <Button type="primary" @click="markingBtn">{{ $t('key1000348') }}</Button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";
import {commonSessionStorage} from "@/utils/common";

export default {
  name: "overtimeOrderProcessingModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      overtimeOrderProcessingModal: false,
      overtimeOrderProcessingTitle: alias1bddc4d174174d47a6dfb0437dace856.t('key1000349'),
      tableColumns: [
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000270'),
          key: 'indexs',
          width: 70,
          align: 'left',
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000350'),
          minWidth: 160,
          key: 'packageCode',
          align: 'left'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000205'),
          key: 'skuInfo',
          align: 'left',
          minWidth: 320,
          render(h, params) {
            if (params.row && params.row._productInfo) {
              let skuImgPath = params.row._productInfo.primaryImage;
              let spuImgPath = params.row._productInfo._spuInfo.primaryImage;
              let imgPath = skuImgPath ? skuImgPath : spuImgPath;
              let spec = '';
              let sku = '';
              if (v.system === 'supplier') {
                let productGoodsSpecifications = params.row._productInfo.productGoodsSpecifications;
                spec = productGoodsSpecifications ? productGoodsSpecifications.map(i => i.name + '：' + i.value).join('、') : '';
                sku = params.row._productInfo.sku;
              } else {
                let productGoodsSpecificationList = params.row._productInfo.productGoodsSpecificationList;
                spec = productGoodsSpecificationList ? productGoodsSpecificationList.map(i => i.name + '：' + i.value).join('、') : '';
                sku = params.row._productInfo.ymsSku;
              }
              return h('div', {class: 'flex mt5 mb5'}, [
                v.tableImg(h, params, null, imgPath),
                h('div', {class: 'ml5'}, [
                  h('div', {class: 'flex align-items-start mb5'}, [
                    h('p', alias1bddc4d174174d47a6dfb0437dace856.t('key1000085')),
                    h('p', {style: {flex: 1}}, params.row._productInfo._spuInfo.name),
                  ]),
                  h('div', {class: 'flex align-items-start mb5'}, [
                    h('p', 'SKU：'),
                    h('p', {style: {flex: 1}}, sku),
                  ]),
                  h('div', {class: 'flex align-items-start mb5'}, [
                    h('p', alias1bddc4d174174d47a6dfb0437dace856.t('key1000206')),
                    h('p', {style: {flex: 1}, class: 'color-green'}, spec),
                  ]),
                ])
              ]);
            }
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000351'),
          key: 'quantity',
          width: 110,
          align: 'left',
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000352'),
          key: 'outOfStockQuantity',
          minWidth: 150,
          align: 'left',
          slot: 'outOfStockQuantity'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000353'),
          key: 'expectedDeliveryTime',
          minWidth: 220,
          align: 'left',
          slot: 'expectedDeliveryTime'
        }
      ],
      originalColumns: [],
      tableForm: {
        tableData: [],
      },
      overtimeOrderText: '',
      radioType: 'order',
      operationType: null,
      typeTalg: null,
      originalData: [],
    }
  },
  computed: {
    tableSkuColumns() {
      return this.tableColumns.filter((item) => {
        return item.key !== 'packageCode'
      });
    }
  },
  methods: {
    // 初始化数据
    initOvertimeOrderProcessingData(type, originalData, operationType) {
      let v = this;
      let titleObj = {
        timeout: alias1bddc4d174174d47a6dfb0437dace856.t('key1000349'),
        mark: alias1bddc4d174174d47a6dfb0437dace856.t('key1000354')
      }
      let textObj = {
        timeout: alias1bddc4d174174d47a6dfb0437dace856.t('key1000355'),
        mark: alias1bddc4d174174d47a6dfb0437dace856.t('key1000356')
      };
       // 默认取缓存的radio的值，没有缓存值默认是order
      v.radioType = commonSessionStorage.getItem('radioDimensionType') || 'order';
      v.typeTalg = type;
      v.operationType = operationType;
      v.overtimeOrderText = textObj[type];
      v.overtimeOrderProcessingTitle = titleObj[type];
      v.originalColumns = JSON.parse(JSON.stringify(v.tableColumns));
      v.handleTimedSentData(originalData)
    },
    // 处理超时未发的数据
    handleTimedSentData(data) {
      let v = this;
      let newData = [];
      if (data.length > 0) {
        data.map((item, idx) => {
          let packageInfoOrderGoodsBos = item.packageInfoOrderGoodsBos || [];
          if (packageInfoOrderGoodsBos.length > 0) {
            let length = packageInfoOrderGoodsBos.length;
            packageInfoOrderGoodsBos.map((ele, index) => {
              let estimateArrivalOfGoodsTime = ele.estimateArrivalOfGoodsTime;
              if (estimateArrivalOfGoodsTime) {
                ele.estimateArrivalOfGoodsTime = v.$uDate.getDateTime(estimateArrivalOfGoodsTime)
              }
              // 缺货时，缺货数量默认等于商品数量
              if (ele.abnormalStatus === 2) {
                ele.outOfStockQuantity = ele.quantity;
              }
              ele['indexs'] = idx + 1;
              ele['packageCode'] = item.packageCode;
              ele['length'] = length;
              ele['currentTalg'] = (length > 1 && index === 0);
              newData.push(ele);
            });
          }
        });
        if (newData.length > 0) {
          newData.map((item, idx) => {
            item['tableLength'] = newData.length;
            if (item.currentTalg) {
              item['currentIndex'] = idx;
            }
          });
          let ymsProductGoodsIds = [];
          let productGoodsIds = [];
          // 运营
          if (v.system === 'yms') {
            newData.map((item) => {
              ymsProductGoodsIds.push(item.ymsProductGoodsId)
            });
            ymsProductGoodsIds = v.uniqueFunc(ymsProductGoodsIds);
            if (ymsProductGoodsIds.length > 0) {
              (async () => {
                v.originalData = await v.getProductInfoByIds(ymsProductGoodsIds, newData);
                v.handleData();
              })();
            }
          }
          // 供应商
          else {
            newData.map((item) => {
              productGoodsIds.push(item.productGoodsId);
            });
            productGoodsIds = v.uniqueFunc(productGoodsIds);
            if (productGoodsIds.length > 0) {
              (async () => {
                v.originalData = await v.getProductInfoByIds(
                  productGoodsIds,
                  newData,
                  false,
                  false,
                  false,
                  api.post_productInfo_queryByProductGoodsIds,
                  'supplier');
                v.handleData();
              })();
            }
          }
        }
        v.overtimeOrderProcessingModal = true;
      }
    },
    // 标记缺货
    markingBtn() {
      let v = this;
      let urlObj = {
        yms: api.post_ymsSubPackageInfo_markOutStock,
        supplier: api.post_packageInfo_markOutStock
      }
      let dateList = [];
      let url = urlObj[v.system];
      if (v.tableForm.tableData.length > 0) {
        let list = [];
        let newData = JSON.parse(JSON.stringify(v.tableForm.tableData));
        v.$refs['tableForm'].validate((valid) => {
          if (valid) {
            newData.map((item) => {
              let estimateArrivalOfGoodsTime = null;
              let outOfStockQuantity;
              if (item.abnormalStatus === 2 && item.goodsStatus === 1 && item.estimateArrivalOfGoodsTime) {
                estimateArrivalOfGoodsTime = v.$uDate.getDateTime(item.estimateArrivalOfGoodsTime);
                dateList.push(estimateArrivalOfGoodsTime);
              }
              // 缺货
              if (item.abnormalStatus === 2) {
                outOfStockQuantity = item.outOfStockQuantity;
              }
              // 不缺货
              else {
                outOfStockQuantity = null;
                estimateArrivalOfGoodsTime = null;
              }
              list.push({
                estimateArrivalOfGoodsTime: estimateArrivalOfGoodsTime,
                markType: item.abnormalStatus === 1 ? 3 : item.goodsStatus, // 货品状态 1.正常 2.停售
                operationType: v.operationType, // 操作类型 1.标记缺货 2.更新标记 3.标记到货
                outOfStockQuantity: outOfStockQuantity,
                packageCodes: [item.packageCode],
                productGoodsId: v.system === 'yms' ? item.ymsProductGoodsId : item.productGoodsId
              })
            });
            // 校验预计到货时间要大于今天的日期
            if (dateList.length > 0) {
              for (let i = 0; i < dateList.length; i++) {
                let item = dateList[i];
                if (v.validateArrivalDate(item)) {
                  v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1000357'));
                  return false;
                }
              }
            }
            if (list.length > 0) {
              v.axios.post(url, list, {loading: true, loadignText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(response => {
                if (response.data.code === 0) {
                  v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000358'));
                  v.overtimeOrderProcessingModal = false;
                  v.$emit('updateData', true);
                }
              });
            }
          }
        });
      }
    },
    // 监听弹窗
    overtimeOrderProcessingChange(value) {
      if (!value) {
        this.originalData = [];
        this.tableForm.tableData = [];
        this.operationType = null;
        this.typeTalg = null;
      }
    },
    // 处理当按照不同维度展示数据
    handleData(val) {
      let v = this;
      let skuOriginalData = [];
      let newSkuData = [];
      v.tableForm.tableData = [];
      if (val) {
        commonSessionStorage.setItem('radioDimensionType', val);
      }
      if (v.originalData.length > 0) {
        if (v.radioType === 'sku') {
          skuOriginalData = JSON.parse(JSON.stringify(v.originalData));
          if (skuOriginalData.length > 0) {
            newSkuData = skuOriginalData.reduce((accumulator, current) => {
              let existingProduct;
              if (v.system === 'yms') {
                existingProduct = accumulator.find(item => item._productInfo.ymsSku === current._productInfo.ymsSku);
              } else {
                existingProduct = accumulator.find(item => item._productInfo.sku === current._productInfo.sku);
              }
              if (existingProduct) {
                existingProduct.quantity += current.quantity;
              } else {
                accumulator.push(current);
              }

              return accumulator;
            }, []);
            newSkuData.map((item, idx) => {
              item['indexs'] = idx + 1;
              if (item.abnormalStatus === 2) {
                item.outOfStockQuantity = item.quantity;
              }
            });
            v.tableForm.tableData = newSkuData;
          }
        } else {
          let newList = JSON.parse(JSON.stringify(v.originalData));
          newList.map((item) => {
            if (item.abnormalStatus === 2) {
              item.outOfStockQuantity = item.quantity;
            }
          })
          v.tableForm.tableData = newList;
        }
      }
    },
    // 按照订单维度合并单元格
    handleOrderSpan({row, column, rowIndex, columnIndex}) {
      let v = this;
      let columnList = [];
      let handleKeyList = ['indexs', 'packageCode'];
      if (v.tableColumns.length > 0) {
        v.tableColumns.map((item, index) => {
          if (handleKeyList.includes(item.key)) {
            columnList.push(index);
          }
        })
      }
      if (columnList.includes(columnIndex)) {
        if (row.length > 1) {
          if (rowIndex === row.currentIndex) {
            return [row.length, 1]
          } else {
            for (let i = 0; i <= row.tableLength; i++) {
              if (rowIndex === i) {
                return [0, 0]
              }
            }
          }
        }
      }
    },
    // 校验预计到货时间
    validateArrivalDate(value) {
      let talg = false;
      const selectedDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const selectedDateWithoutTime = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      if (selectedDateWithoutTime <= today) {
        talg = true;
      }
      return talg;
    },
    // 切换缺货数量类型
    changeAbnormalStatus(val, index, item) {
      // 不缺货
      if (val === 1) {
        this.tableForm.tableData[index].estimateArrivalOfGoodsTime = null;
      }
      // 缺货
      else {
        this.tableForm.tableData[index].outOfStockQuantity = item.quantity;
      }
      this.$forceUpdate();
    }
  }
}
</script>

<style lang="less" scoped>

</style>