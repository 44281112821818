<template>
  <div class="container_box">
    <Tabs v-model="pageParams.orderTabType" :animated="false" class="tabs_styles" @on-click="changeTab">
      <TabPane v-for="item in orderTabTypeList" :label="item.title" :name="item.value"></TabPane>
    </Tabs>
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <dropDownFilter
              ref="dropDownFilter"
              inputType="textarea"
              :selectStyle="{width: '95px'}"
              :dropDownInputList="dropDownInputList"
              :defaultSelectValue="defaultSelectValue"
              @changeInput="changeInput"
              @updateInputValue="updateInputValue">
            </dropDownFilter>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol"
            v-if="['0', '1'].includes(pageParams.orderTabType)">
            <FormItem :label="$t('key1003422')" prop="packageOrderStatusList">
              <Select v-model="pageParams.packageOrderStatusList" transfer multiple :placeholder="$t('key1003538')">
                <Option v-for="item in orderStatusList" :key="item.value"
                  :value="item.value">{{ item.title }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol"
            v-if="['0', '1', '2', '4', '5'].includes(pageParams.orderTabType)">
            <FormItem :label="$t('key1003520')" prop="outPackageOrderStatusList">
              <Select v-model="pageParams.outPackageOrderStatusList" transfer multiple
                :placeholder="$t('key1003525')">
                <Option v-for="item in deliveryOrderStatusList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <dropDownDateTime
              ref="dropDownDateTime"
              :dropDownTimeList="dropDownTimeList"
              :defaultSelectValue="defaultSelectTimeValue"
              @changeInput="changeTimeInput"
              @selectTime="selectTime"
              @resetTime="resetTime">
            </dropDownDateTime>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol"
            v-if="['0', '4'].includes(pageParams.orderTabType)">
            <FormItem :label="$t('key1003539')" prop="settlementStatus">
              <Select v-model="pageParams.settlementStatus" transfer multiple :placeholder="$t('key1003540')">
                <Option v-for="item in settlementStatusList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005459')" prop="ymsWarehouseId">
              <Select v-model="pageParams.ymsWarehouseId" filterable>
                <Option v-for="item in warehouseList" :key="item.ymsWarehouseId" :value="item.ymsWarehouseId">{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" icon="md-refresh" class="mr12">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box flex align-items-center justify-content-between">
      <div class="flex align-items-center">
        <!--批量发货-->
        <Dropdown transfer @on-click="bulkShipmentBtn" class="mr15"
          v-if="['1'].includes(pageParams.orderTabType) && isWarehouseManagement === 'N' && getPermission('wmsPickupOrder_batchDeliver')">
          <Button type="primary">
            <span>{{ $t('key1005431') }}</span>
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="1">{{ $t('key1005432') }}</DropdownItem>
            <DropdownItem name="2">{{ $t('key1005433') }}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <!--导出订单-->
        <Dropdown transfer @on-click="changeExportBtn" class="mr15"
          v-if="getPermission('packageInfo_exportPackageInfoOrder')">
          <Button>
            <span>{{ $t('key1003528') }}</span>
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="1">{{ $t('key1002230') }}</DropdownItem>
            <DropdownItem name="2">{{ $t('key1002231') }}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <!--标记缺货-->
        <Button
          class="mr15"
          v-if="['1'].includes(pageParams.orderTabType) && getPermission('packageInfo_markOutStock')"
          @click="markOutStockBtn('all',null, 1)">{{ $t('key1000354') }}
        </Button>
        <!--标记到货-->
        <Button
          class="mr15"
          v-if="['1'].includes(pageParams.orderTabType) && getPermission('packageInfo_markOutStock')"
          @click="batchMarkingBtn('all')">{{ $t('key1003552') }}
        </Button>
        <!--打印配货清单-->
        <Button
          class="mr15"
          v-if="['1'].includes(pageParams.orderTabType) && getPermission('packageInfo_printPrepareGoods')"
          @click="printDistributionBtn">{{ $t('key1004151') }}
        </Button>
        <!--打印YMS条码-->
        <Dropdown v-if="['1'].includes(pageParams.orderTabType) && batchButtonPermissions(printBarcodeList)"
          transfer @on-click="printBarcodeBtn" class="mr15">
          <Button>
            <span>{{ $t('key1000825') + configCharacters + $t('key1000827') }}</span>
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem v-for="item in printBarcodeList" :name="item.name" v-if="getPermission(item.permissionKey)">
              {{ item.title }}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <!--批量接单-->
        <Button
          class="mr15"
          v-if="['6'].includes(pageParams.orderTabType) && getPermission('packageInfo_receivingOrders')"
          @click="takingOrdersBtn('all', 1)">{{ $t('key1004908') }}
        </Button>
      </div>
      <div class="flex align-items-center">
        <!--自定义列-->
        <Button @click="$refs['customColumns'].initCustomColumnsData()">{{ $t('key1000647') }}</Button>
      </div>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        ref="tableDom"
        highlight-row
        :loading="tableLoading"
        border
        max-height="530"
        :columns="tableColumns"
        :data="tableData"
        :span-method="handleSpan"
        @on-selection-change="tableChange">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--导出数据字段弹窗-->
    <exportFieldsModal
      ref="exportFields"
      :exportFieldsTitle="$t('key1003528')"
      paramKeyColums="supplierPackageInfoOrderExportColumns"
      :customFieldExportList="customFieldExportList"
      @exportFieldsDataBtn="exportFieldsDataBtn">
    </exportFieldsModal>
    <!--配货清单格式弹窗-->
    <distributionListFormatModal ref="distributionListFormat"
      @updateSelect="updateSelect"></distributionListFormatModal>
    <!--日志弹窗-->
    <dailyRecordModal ref="dailyRecord"></dailyRecordModal>
    <!--扫描换单-->
    <scanOrderChangeModal ref="scanOrderChange"></scanOrderChangeModal>
    <!--标记发货弹窗-->
    <logisticsInfoModal ref="logisticsInfo" @updateData="printBoxBtn"></logisticsInfoModal>
    <!--打印箱唛弹窗-->
    <printCaseMarkModal
      ref="printTalg"
      :apiUrl="apiUrl"
      :printUpdate="true"
      service="yms-supplier-service"
      typeTalg="packingManage"
      @printUpdateData="search('update')">
    </printCaseMarkModal>
    <!--自定义列组件-->
    <customColumnsDrawer
      ref="customColumns"
      customColumnsKey="supplierOrderListColumns"
      :originalColumns="originalColumns"
      :defaultSelectKeyList="defaultSelectKeyList"
      @customColumnsData="customColumnsData">
    </customColumnsDrawer>
    <!--标记缺货弹窗-->
    <overtimeOrderProcessingModal
      ref="overtimeOrderProcess"
      @updateData="pageParamsStatus = true">
    </overtimeOrderProcessingModal>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import {pageJump, amountHandel, isValueEmpty, setConfigCharacters} from "@/utils/common";
import dropDownFilter from "@/components/common/dropDownFilter.vue";
import exportFieldsModal from "@/components/common/exportFieldsModal.vue";
import distributionListFormatModal from "@/views/supplier/components/supplierOrder/distributionListFormatModal.vue";
import dailyRecordModal from "@/views/supplier/components/supplierOrder/dailyRecordModal.vue";
import scanOrderChangeModal from "@/views/supplier/components/supplierOrder/scanOrderChangeModal.vue";
import logisticsInfoModal from "@/components/common/logisticsInfoModal.vue";
import printCaseMarkModal from "@/components/common/printCaseMarkModal.vue";
import dropDownDateTime from "@/components/common/dropDownDateTime";
import customColumnsDrawer from "@/components/common/customColumnsDrawer";
import overtimeOrderProcessingModal from "@/components/common/overtimeOrderProcessingModal";

export default {
  name: "supplierOrderList",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      total: 0,
      pageParamsStatus: false,
      pageParams: {
        orderTabType: '1', // 0:全部 1:待发货 2:已发货 4:已完成 5:已取消
        createdEndTime: null, // 下单结束时间
        createdStartTime: null, // 下单开始时间
        shipedStartTime: null, // 发货开始时间
        shipedEndTime: null, // 发货结束时间
        outPackageOrderStatusList: [], // 出库单状态
        packageCodes: '', // 订单号
        packageOrderStatusList: [], // 订单状态
        outboundTypes: [1, 5], // 出库类型(1:销售出库 2:自有仓备货 3:三方仓备货 4:多发补单 5:分销商备货)
        skus: '', // 供应商sku
        ymsSkus: '', // 云卖sku
        settlementStatus: [], // 结算状态
        ymsWarehouseId: null, // 目的仓库
        pageNum: 1,
        pageSize: 15,
      },
      tableData: [],
      tableColumns: [],
      originalColumns: [
        {
          type: 'selection',
          width: 60,
          /* fixed: 'left',*/
          className: 'selection_styles_box',
          key: 'idx',
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000350'),
          key: 'packageCode',
          align: 'left',
          className: 'set_table_td',
          minWidth: 170
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003198'),
          key: 'warehouseName',
          minWidth: 130,
          align: 'left',
          className: 'set_table_td',
          render(h, params) {
            return h('span', v.getWarehouseName(params.row.ymsWarehouseId));
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002069'),
          key: 'orderStatus',
          align: 'left',
          minWidth: 140,
          render(h, params) {
            let text = '-';
            let orderStatus = params.row.orderStatus;
            let payStatus = params.row.payStatus;
            let waitingAgainSendJson = params.row.waitingAgainSendJson;
            v.orderStatusData.map((item) => {
              if (item.value === orderStatus) {
                if (orderStatus === 1 && payStatus === 1) {
                  text = alias1bddc4d174174d47a6dfb0437dace856.t('key1004668');
                } else {
                  text = item.title;
                }
              }
            })
            // 缺货
            if (orderStatus === 7) {
              let abnormalStatus = params.row.abnormalStatus; // 异常状态 1.正常 2.缺货
              if (abnormalStatus === 2) {
                let outOfStockQuantity = params.row.outOfStockQuantity;
                let outOfStockText = `${alias1bddc4d174174d47a6dfb0437dace856.t('key1003348')}${outOfStockQuantity}`;
                let estimateArrivalOfGoodsTime = v.$uDate.dealTime(params.row.estimateArrivalOfGoodsTime);
                let goodsStatus = params.row.goodsStatus; //货品状态 1.正常 2.停售
                return h('div', [
                  h('p', {class: 'redColor mt5 mb5'}, text),
                  h('p', {class: 'redColor mt5 mb5'}, outOfStockText),
                  goodsStatus === 1 ? h('div', [
                    h('p', {class: 'redColor'}, alias1bddc4d174174d47a6dfb0437dace856.t('key1003350')),
                    h('div', {class: 'flex align-items-center mt5 mb5'}, [
                      h('span', {class: 'redColor'}, estimateArrivalOfGoodsTime),
                      v.getPermission('packageInfo_markOutStock') ? h('Icon', {
                        props: {type: 'md-create', color: '#ff3300'},
                        style: {
                          marginLeft: '5px',
                          fontSize: '17px',
                          cursor: 'pointer',
                          position: 'relative',
                          top: '-2px'
                        },
                        on: {
                          click: () => {
                            v.markOutStockBtn('single', params.row.packageId, 2)
                          }
                        }
                      }) : ''
                    ])
                  ]) : '',
                  goodsStatus === 2 ? h('p', {class: 'redColor mt5 mb5'}, alias1bddc4d174174d47a6dfb0437dace856.t('key1000070')) : ''
                ])
              }
              if (abnormalStatus === 1) {
                return h('p', {class: 'mt5 mb5'}, alias1bddc4d174174d47a6dfb0437dace856.t('key1001182'));
              }
            }
            // 待重发
            else if (orderStatus === 3) {
              if (waitingAgainSendJson) {
                let obj = JSON.parse(waitingAgainSendJson);
                let items = {
                  '1': `${alias1bddc4d174174d47a6dfb0437dace856.t('key1003192')}${obj.pickupOrderNumber}${alias1bddc4d174174d47a6dfb0437dace856.t('key1003542')}`,
                  '2': `${alias1bddc4d174174d47a6dfb0437dace856.t('key1003192')}${obj.pickupOrderNumber} ${alias1bddc4d174174d47a6dfb0437dace856.t('key1003543')}`,
                }
                let content = items[obj.type];
                return h('div', {class: 'flex align-items-center'}, [
                  h('span', {class: 'redColor'}, text),
                  h('Tooltip', {
                      props: {
                        transfer: true,
                        maxWidth: 250,
                        placement: 'top',
                        content: content
                      },
                      style: {
                        marginLeft: '5px'
                      }
                    },
                    [
                      h('Icon', {
                        props: {
                          type: 'md-help-circle',
                          size: 17
                        },
                        style: {
                          cursor: 'pointer',
                          color: '#ff3300'
                        }
                      })
                    ])
                ])
              }
            } else {
              return h('div', {
                class: orderStatus === 6 ? 'redColor' : ''
              }, text);
            }
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002942'),
          key: 'settlementStatus',
          align: 'left',
          minWidth: 140,
          className: 'set_table_td',
          render(h, params) {
            let text = '-';
            let settlementStatus = params.row.settlementStatus;
            v.settlementStatusList.map((item) => {
              if (item.value === settlementStatus) {
                text = item.title;
              }
            })
            return h('span', text);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000787'),
          key: 'commodityInfo',
          align: 'left',
          minWidth: 320,
          className: 'set_table_td',
          render(h, params) {
            let supplierSpu = params.row.supplierSpu;
            let ymsSpu = params.row.ymsSpu;
            let imgPath = params.row.imgPath;
            return h('div', {class: 'flex'}, [
              v.tableImg(h, params, null, imgPath),
              h('div', {
                class: 'text-align-left pl10'
              }, [
                h('Alink', {
                  props: {
                    linkName: params.row.name,
                    textAlign: 'left',
                    className: 'mb5',
                    AlinkClass: 'ellipsis_1'
                  },
                  on: {
                    AlinkBtn: () => {
                      const {href} = v.$router.resolve({
                        path: '/productDetails',
                        query: {
                          type: 'look',
                          productId: params.row._productInfo.productId
                        }
                      });
                      window.open(href, '_blank');
                    }
                  }
                }),
                h('div', {class: 'flex align-items-start mb5'}, [
                  h('p', alias1bddc4d174174d47a6dfb0437dace856.t('key1000397')),
                  h('p', {style: {flex: 1}, class: 'font-weight-bold'}, supplierSpu),
                ]),
                h('div', {class: 'flex align-items-start mb5'}, [
                  h('p', `${setConfigCharacters}SPU：`),
                  h('p', {style: {flex: 1}}, ymsSpu),
                ])
              ])
            ])
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000205'),
          minWidth: 240,
          key: 'skuInfo',
          align: 'left',
          render: (h, params) => {
            let list = [];
            let supplierSku = params.row.supplierSku;
            let ymsSku = params.row.ymsSku;
            let productGoodsSpecifications = params.row.productGoodsSpecifications || [];
            if (productGoodsSpecifications.length > 0) {
              list = productGoodsSpecifications.map((item) => {
                return item.value
              });
            }
            return h('div', [
              h('div', {class: 'flex align-items-start mt2 mb2'}, [
                h('p', alias1bddc4d174174d47a6dfb0437dace856.t('key1003546')),
                h('p', {style: {flex: 1}, class: 'font-weight-bold'}, ymsSku),
              ]),
              h('div', {class: 'flex align-items-start mt2 mb2'}, [
                h('p', 'SKU：'),
                h('p', {style: {flex: 1}}, supplierSku),
              ]),
              h('p', {class: 'mt2 mb2 color-green'}, list.join(' • '))
            ])
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002867'),
          key: 'quantity',
          align: 'left',
          minWidth: 140,
          render(h, params) {
            let orderTabType = v.pageParams.orderTabType;
            let orderStatus = params.row.orderStatus;
            let payStatus = params.row.payStatus;
            let quantity = params.row.quantity;
            let actualSortingNumber = [0, 1, 2].includes(orderStatus) || (orderStatus === 1 && payStatus === 1) ?
              '-' : params.row.actualSortingNumber;
            return h('div', {class: 'flex flex-direction'}, [
              h('p', {class: 'mt2 mb1'}, alias1bddc4d174174d47a6dfb0437dace856.t('key1003547') + quantity),
              ['0', '4', '5'].includes(orderTabType) ? h('p', {class: 'mt2 mb1'}, alias1bddc4d174174d47a6dfb0437dace856.t('key1003548') + actualSortingNumber) : '',
            ]);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003197'),
          key: 'actualSortingNumber',
          align: 'left',
          minWidth: 100,
          render(h, params) {
            let talg = false;
            let actualSortingNumber = params.row.actualSortingNumber || '-';
            let doneAssignedNumber = params.row.doneAssignedNumber;
            if (actualSortingNumber > 0 && doneAssignedNumber > 0 && actualSortingNumber !== doneAssignedNumber) {
              talg = true;
            }
            return h('span', {class: talg ? 'redColor' : ''}, actualSortingNumber)
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002697'),
          key: 'totalPrice',
          align: 'left',
          minWidth: 240,
          className: 'set_table_td',
          renderHeader: (h, params) => {
            return h('p', alias1bddc4d174174d47a6dfb0437dace856.t('key1002697') + (v.currencyState ? '(' + v.currencyState + ')' : ''));
          },
          render(h, params) {
            let doneAssignedNumber = params.row.doneAssignedNumber;
            let actualSortingNumber = params.row.actualSortingNumber;

            function hanleData(key, val) {
              let price = amountHandel(params.row[key]);
              let currency = params.row[val] || v.currencyState;
              return !isValueEmpty(price) ? (price + ' ' + currency) : '-';
            }

            let totalPriceText = hanleData('totalPrice', 'totalPriceCurrency');
            let originalAmountText = hanleData('originalAmount', 'originalAmountCny');
            let settlementAmountText = hanleData('settlementAmount', 'settlementAmountCny');
            let orderStatus = params.row.orderStatus; // 0.待接单 1待发货 2已发货 3待重发 4已完成 5已取消 6超时未发 7缺货中
            switch (orderStatus) {
              case 0:
                return h('div', [
                  h('p', {class: 'mt2 mb2'}, `${alias1bddc4d174174d47a6dfb0437dace856.t('key1003315')}${originalAmountText}`),
                  h('p', {class: 'mt2 mb2'}, `${alias1bddc4d174174d47a6dfb0437dace856.t('key1003316')}${totalPriceText}`)
                ]);
                break;
              case 4:
                if (doneAssignedNumber !== actualSortingNumber) {
                  return h('div', [
                    h('p', {class: 'mt2 mb2'}, `${alias1bddc4d174174d47a6dfb0437dace856.t('key1003314')}${totalPriceText}`),
                    h('p', {class: 'mt2 mb2'}, `${alias1bddc4d174174d47a6dfb0437dace856.t('key1005460')}${settlementAmountText}`)
                  ]);
                } else {
                  return h('span', totalPriceText)
                }
                break;
              default:
                return h('span', totalPriceText)
            }
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002402'),
          key: 'packageStatus',
          align: 'left',
          className: 'set_table_td',
          minWidth: 130,
          render(h, params) {
            let text = '-';
            let outPackageOrderStatus = params.row.outPackageOrderStatus;
            v.deliveryOrderStatusList.map((item) => {
              if (item.value === outPackageOrderStatus) {
                text = item.title;
              }
            })
            return h('span', {
              style: {
                color: outPackageOrderStatus === 6 ? '#FF0000' : ''
              }
            }, text);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003494'),
          key: 'createdTime',
          align: 'left',
          minWidth: 170,
          className: 'set_table_td',
          render(h, params) {
            return h('span', params.row.createdTime ? v.$uDate.dealTime(params.row.createdTime, 'fulltime') : '-');
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003536'),
          key: 'despatchTime',
          align: 'left',
          minWidth: 170,
          className: 'set_table_td',
          render(h, params) {
            return h('span', params.row.despatchTime ? v.$uDate.dealTime(params.row.despatchTime, 'fulltime') : '-');
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003550'),
          key: 'ymsPackageStatusTime',
          align: 'left',
          minWidth: 170,
          className: 'set_table_td',
          renderHeader(h, params) {
            return h('div', {class: 'flex align-items-center'}, [
              h('span', alias1bddc4d174174d47a6dfb0437dace856.t('key1003550')),
              h('Tooltip', {
                props: {
                  offset: 13,
                  transfer: true,
                  maxWidth: 300,
                  content: alias1bddc4d174174d47a6dfb0437dace856.t('key1003551'),
                  placement: 'top-end'
                }
              }, [
                h('Icon', {
                  props: {
                    type: 'md-help-circle',
                    size: 17
                  },
                  class: 'cursor ml5 themeColor'
                })
              ])
            ]);
          },
          render(h, params) {
            return h('span', params.row.ymsPackageStatusTime ? v.$uDate.dealTime(params.row.ymsPackageStatusTime, 'fulltime') : '-');
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003483'),
          key: 'requestRehandleTime',
          align: 'left',
          minWidth: 170,
          className: 'set_table_td',
          render(h, params) {
            if (['0', '5'].includes(v.pageParams.orderTabType)) {
              return h('span', params.row.requestRehandleTime ? v.$uDate.dealTime(params.row.requestRehandleTime, 'fulltime') : '-');
            } else {
              return h('span', '-');
            }
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003544'),
          key: 'remainingDeliveryTime',
          align: 'left',
          minWidth: 170,
          className: 'set_table_td',
          render(h, params) {
            let remainingDeliveryTime = params.row.remainingDeliveryTime;
            return h('span', remainingDeliveryTime || '-');
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000278'),
          width: 210,
          key: 'options',
          /*fixed: 'right',*/
          className: 'options_styles_box',
          align: 'left',
          render(h, params) {
            let orderStatus = params.row.orderStatus; // 0 待接单、1 待发货、2 已发货、3 待重发、4 已完成、5 已取消、6 超时未发、7 缺货中
            let payStatus = params.row.payStatus;
            let packageId = params.row.packageId;
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1000407'), // 所有状态下均可以打印条码
                    show: v.getPermission('packageInfo_appendPackageInfoOrderExpressBill'),
                    clickFn: () => {
                      v.handleOrderBarcode('single', packageId);
                    }
                  },
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1003552'),
                    show: orderStatus === 7 && payStatus !== 1 && v.getPermission('packageInfo_markOutStock'), // 缺货中
                    clickFn: () => {
                      v.batchMarkingBtn('single', params.row);
                    }
                  },
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1000354'),
                    color: '#FF0000',
                    show: [1, 3, 6].includes(orderStatus) && payStatus !== 1 && v.getPermission('packageInfo_markOutStock'),  // 待发货、待重发、超时未发
                    clickFn: () => {
                      v.markOutStockBtn('single', params.row.packageId, 1)
                    }
                  },
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1003553'),
                    show: orderStatus === 7 && v.getPermission('packageInfo_markOutStock'),
                    clickFn: () => {
                      v.markOutStockBtn('single', params.row.packageId, 2)
                    }
                  },
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1004934'),
                    show: orderStatus === 0 && v.getPermission('packageInfo_receivingOrders'),
                    clickFn: () => {
                      v.takingOrdersBtn('single', 1, params.row.packageId)
                    }
                  },
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1005461'),
                    show: orderStatus === 0 && v.getPermission('packageInfo_receivingOrders'),
                    clickFn: () => {
                      v.takingOrdersBtn('single', 2, params.row.packageId)
                    }
                  },
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1001728'),
                    show: true,
                    clickFn: () => {
                      v.$refs['dailyRecord'].initDailyRecordData(params.row);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      selectTableData: [],
      orderStatusList: [],
      orderStatusData: [
        {value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1004957')},
        {value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001182')},
        {value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001169')},
        {value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003323')},
        {value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003324')},
        {value: 5, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')},
        {value: 6, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003554')},
        {value: 7, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001277')},
      ],
      deliveryOrderStatusList: [],
      orderTabTypeList: [
        {value: '0', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
        {
          value: '6',
          title: (h) => {
            return h('div', [
              h('span', alias1bddc4d174174d47a6dfb0437dace856.t('key1004957')),
              h('Badge', {
                props: {
                  count: v.pendingOrdersTotal,
                  overflowCount: 999
                }
              })
            ])
          }
        },
        {value: '7', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005462')},
        {
          value: '1',
          title: (h) => {
            return h('div', [
              h('span', alias1bddc4d174174d47a6dfb0437dace856.t('key1001182')),
              h('Badge', {
                props: {
                  count: v.toBeShippedTotal,
                  overflowCount: 999
                }
              })
            ])
          }
        },
        {value: '2', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001169')},
        {value: '4', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003324')},
        {value: '5', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')},
      ],
      dropDownInputList: [
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000350'), value: 1, placeholder: alias1bddc4d174174d47a6dfb0437dace856.t('key1005463'), parameterKey: 'packageCodes'},
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000402'), value: 2, placeholder: alias1bddc4d174174d47a6dfb0437dace856.t('key1005464'), parameterKey: 'skus'},
        {title: `${setConfigCharacters}SKU`, value: 3, placeholder: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000287')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1003421')}`, parameterKey: 'ymsSkus'}
      ],
      defaultSelectValue: 1,
      exportType: '1',
      customFieldExportList: [
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000596'),
          titleKey: 'basicInfoColums',
          selectList: [], // 选中导出字段的key
          checkBoxList: [
            {paramKey: 'packageCode', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000350')},
            {paramKey: 'warehouseName', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003198')},
            {paramKey: 'orderStatusStr', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1002069')},
            {paramKey: 'name', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1001244')},
            {paramKey: 'sku', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000402')},
            {paramKey: 'quantity', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1002748')},
            {paramKey: 'variations', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000643')},
            {paramKey: 'outOrderStatusStr', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1002402')},
            {paramKey: 'totalPriceStr', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1002697')},
            {paramKey: 'createdTime', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003494')},
            {paramKey: 'requestRehandleTime', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003483')},
            {paramKey: 'despatchTime', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003536')},
            {paramKey: 'ymsPackageStatusTime', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003550')},
          ]
        }
      ],
      pendingOrdersTotal: 0,
      toBeShippedTotal: 0,
      printBarcodeList: [
        {name: '1', permissionKey: 'packageInfo_appendYmsSkuExpressBill', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005436')},
        {name: '2', permissionKey: 'packageInfo_appendPackageInfoOrderExpressBill', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005437')},
      ],
      apiUrl: api.put_wmsPickupOrder_printWmsPickupOrder,
      recordParams: {}, // 记录当前筛选条件的参数
      settlementStatusList: [
        {value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003561')},
        {value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002943')},
        {value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002944')}
      ],
      dropDownTimeList: [
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003494'), value: 1, parameterKey: ['createdStartTime', 'createdEndTime']},
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003536'), value: 2, parameterKey: ['shipedStartTime', 'shipedEndTime']},
      ],
      defaultSelectTimeValue: 1,
      customColumnsKey: 'supplierOrderListColumns',
      setColumnsList: [],
      defaultSelectKeyList: ['packageCode', 'idx', 'options'],
      warehouseList: [],
    }
  },
  computed: {
    filterKeyList() {
      let parameterKeyList = this.dropDownInputList.map((item) => {
        return item.parameterKey
      });
      return parameterKeyList;
    },
    // 是否开启仓储管理配置
    isWarehouseManagement() {
      return this.$store.state.isWarehouseManagement;
    },
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  created() {
    let v = this;
    if (['0', '4', '5'].includes(v.pageParams.orderTabType)) {
      v.setDefaultTimerParams('three_months', 1);
    }
    // 获取消息中心跳转过来的参数
    let packageCode = localStorage.getItem('supplierOrderListQuery') ? JSON.parse(localStorage.getItem('supplierOrderListQuery')) : null;
    if (packageCode) {
      v.$nextTick(() => {
        v.pageParams.orderTabType = '0';
        v.defaultSelectValue = 1;
        v.$refs.dropDownFilter.inputValue = packageCode;
        v.pageParams.packageCodes = packageCode;
        v.initFun();
      });
      setTimeout(() => {
        localStorage.removeItem('supplierOrderListQuery');
        v.$store.commit('updateSystemMenu', false);
      }, 2000);
    } else {
      v.initFun();
    }
  },
  methods: {
    // 初始化接口数据
    initFun() {
      this.warehouseList = [];
      (async () => {
        await this.getPendingProcessingNum();
        await this.getBelongingWarehouseData(this.userInfo.merchantId).then((data) => {
          this.warehouseList = data;
        });
        await this.search();
      })();
    },
    // 重置数据
    reset() {
      let v = this;
      v.$refs['pageParams'].resetFields();
      v.changeInput(1);
      v.selectTableData = [];
      v.changeTimeInput(1);
      if (['0', '4', '5'].includes(v.pageParams.orderTabType)) {
        v.setDefaultTimerParams('three_months', 1);
      }
      v.getList();
    },
    // 查询按钮
    search() {
      this.pageParams.pageNum = 1;
      this.getList();
    },
    // 列表查询的方法
    getList() {
      let v = this;
      v.selectTableData = [];
      v.total = 0;
      v.tableData = [];
      v.optionBtnWidth = [];
      v.handleColumns();
      v.getPendingProcessingNum();
      if (v.getPermission('packageInfo_queryPackageInfoOrder')) {
        v.tableLoading = true;
        v.axios.post(api.post_packageInfo_queryPackageInfoOrder, v.handleParameter()).then(response => {
          v.recordParams = v.handleParameter();
          if (response.data.code === 0) {
            let data = response.data.datas;
            let list = data.list || [];
            v.total = data.total;
            if (list.length > 0) {
              v.handleTableData(list);
            } else {
              v.tableLoading = false;
            }
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 处理列表数据
    handleTableData(data) {
      let v = this;
      let productGoodsIds = [];
      let newData = [];
      data.map((item) => {
        let packageInfoOrderGoods = item.packageInfoOrderGoodsBos || [];
        if (packageInfoOrderGoods.length > 0) {
          packageInfoOrderGoods.map((ele, index) => {
            ele.packageCode = item.packageCode;
            ele.ymsWarehouseId = item.ymsWarehouseId;
            newData.push(Object.assign({}, item, ele));
          });
        } else {
          newData.push(item);
        }
      });
      if (newData.length > 0) {
        newData.map((item, index) => {
          if (item.productGoodsId) {
            productGoodsIds.push(item.productGoodsId)
          }
        });
        if (productGoodsIds.length > 0) {
          (async () => {
            productGoodsIds = v.uniqueFunc(productGoodsIds);
            let handleList = await v.getProductInfoByIds(productGoodsIds, newData,
              false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
            if (handleList.length > 0) {
              handleList.map((item) => {
                item.supplierSku = item.sku;
                item.ymsSku = item._productInfo ? item._productInfo.ymsSku : null;
                item.supplierSpu = item._productInfo ? item._productInfo._spuInfo ? item._productInfo._spuInfo.spu : null : null;
                item.ymsSpu = item._productInfo ? item._productInfo._spuInfo ? item._productInfo._spuInfo.ymsSpu : null : null;
                item.productGoodsSpecifications = item._productInfo ? item._productInfo.productGoodsSpecifications : []
              });
              v.tableData = [];
              // 处理分组
              let packageCodeMap = new Map();
              handleList.forEach((item) => {
                let {packageCode, ymsSpu, ymsSku, supplierSpu, supplierSku, productGoodsSpecifications, ...obj} = item;
                let productId = item._productInfo.productId;
                let name = item._productInfo._spuInfo.name;
                let skuImgPath = item._productInfo.primaryImage || '';
                let spuImgPath = item._productInfo._spuInfo.primaryImage || '';
                let imgPath = skuImgPath ? skuImgPath : spuImgPath;

                let packageInfoOrderGoodsBos = item.packageInfoOrderGoodsBos || [];
                // 初始化 packageCode 数据
                if (!packageCodeMap.has(packageCode)) {
                  packageCodeMap.set(packageCode, {packageCode, ...obj, spuList: []});
                }
                let spuList = packageCodeMap.get(packageCode).spuList;
                // 初始化 supplierSpu 数据
                if (!spuList.some((spuItem) => spuItem.supplierSpu === supplierSpu)) {
                  spuList.push({
                    supplierSpu,
                    ymsSpu,
                    productId,
                    name,
                    imgPath,
                    skuList: []
                  });
                }
                let skuList = spuList.find((spuItem) => spuItem.supplierSpu === supplierSpu).skuList;
                // 添加 sku 数据
                let skuObj = {};
                let arr = packageInfoOrderGoodsBos.filter((ele) => {
                  return ele.sku === supplierSku
                });
                if (arr.length > 0) {
                  skuObj = arr[0]
                }
                skuObj = Object.assign({}, skuObj, {supplierSku: supplierSku, ymsSku: ymsSku, productGoodsSpecifications})
                skuList.push(skuObj);
              });
              // 将 Map 转为数组
              let packageCodeList = Array.from(packageCodeMap.values());

              // 处理合并数据
              function handleMergeData(mergeData) {
                let newList = [];
                mergeData.map((item, idx) => {
                  item.spuList.map((ele) => {
                    ele.skuList.map((talg) => {
                      newList.push({
                        ...item,
                        ...talg,
                        supplierSpu: ele.supplierSpu,
                        ymsSpu: ele.ymsSpu,
                        name: ele.name,
                        imgPath: ele.imgPath
                      })
                    })
                  })
                });
                return newList;
              }

              // 按照ymsSku升序排列
              let list = handleMergeData(packageCodeList);
              const packageCodes = [...new Set(list.map(item => item.packageCode))];
              packageCodes.forEach(code => {
                const itemsWithSameCode = list.filter(item => item.packageCode === code);
                itemsWithSameCode.sort((a, b) => a.ymsSku.localeCompare(b.ymsSku));

                const index = list.findIndex(item => item.packageCode === code);
                list.splice(index, itemsWithSameCode.length, ...itemsWithSameCode);
              });

              v.tableData = list;
              v.updateTableDom('tableDom');
            }
          })();
        } else {
          v.tableData = data;
          v.tableLoading = false;
          v.updateTableDom('tableDom');
        }
      }
    },
    // 合并单元格
    handleSpan({row, column, rowIndex, columnIndex}) {
      let v = this;
      let columnList = [];
      let columnSpuList = [];
      let handleKeyList = ['commodityInfo', 'skuInfo', 'quantity', 'actualSortingNumber', 'orderStatus'];
      let handleSpuKeyList = ['commodityInfo'];

      // 获取列的索引
      if (v.tableColumns.length > 0) {
        v.tableColumns.forEach((item, index) => {
          if (!handleKeyList.includes(item.key)) {
            columnList.push(index);
          }
          if (handleSpuKeyList.includes(item.key)) {
            columnSpuList.push(index);
          }
        });
      }
      // 订单维度合并
      if (columnList.includes(columnIndex)) {
        const colSpan = {rowspan: 1, colspan: 1};
        if (rowIndex > 0 && row.packageCode === v.tableData[rowIndex - 1].packageCode) {
          colSpan.rowspan = 0;
        } else {
          for (let i = rowIndex + 1; i < v.tableData.length; i++) {
            if (v.tableData[i].packageCode === row.packageCode) {
              colSpan.rowspan++;
            } else {
              break;
            }
          }
        }
        return colSpan;
      }
      // spu维度合并
      if (columnSpuList.includes(columnIndex)) {
        const colSpanSpu = {rowspan: 1, colspan: 1};
        const supplierSpu = row.supplierSpu;
        const prevRow = v.tableData[rowIndex - 1] || null;
        const prevSupplierSpu = prevRow ? prevRow.supplierSpu : null;
        const prevPackageCode = prevRow ? prevRow.packageCode : null;
        if (prevPackageCode === row.packageCode && prevSupplierSpu === supplierSpu) {
          colSpanSpu.rowspan = 0;
        } else {
          for (let i = rowIndex + 1; i < v.tableData.length; i++) {
            if (v.tableData[i].packageCode === row.packageCode) {
              const currentSupplierSpu = v.tableData[i].supplierSpu;
              if (currentSupplierSpu === supplierSpu) {
                colSpanSpu.rowspan++;
              } else {
                break;
              }
            } else {
              break;
            }
          }
        }
        return colSpanSpu;
      }
    },

    // 获取选中的时间
    selectTime(data) {
      let v = this;
      let time = data.date;
      let timeList = v.defaultTimePeriod(time);
      let current = data.value;
      v.dropDownTimeList.map((item) => {
        if (item.value === current) {
          let startTimeKey = item.parameterKey[0];
          let endTimeKey = item.parameterKey[1];
          v.$nextTick(() => {
            v.$refs['dropDownDateTime'].timeList = v.defaultTimePeriod(time, false);
          });
          v.pageParams[startTimeKey] = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.pageParams[endTimeKey] = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
          v.$forceUpdate();
        }
      });
    },
    // 改变默认时间筛选下拉选项的值
    changeTimeInput(value) {
      this.defaultSelectTimeValue = value;
      this.$refs['dropDownDateTime'].timeList = [];
      this.dropDownTimeList.map((item) => {
        let startTimeKey = item.parameterKey[0];
        let endTimeKey = item.parameterKey[1];
        this.pageParams[startTimeKey] = null;
        this.pageParams[endTimeKey] = null;
      });
    },
    // 重置选中的时间
    resetTime(value) {
      this.dropDownTimeList.map((item) => {
        if (item.value === value) {
          let startTimeKey = item.parameterKey[0];
          let endTimeKey = item.parameterKey[1];
          this.pageParams[startTimeKey] = null;
          this.pageParams[endTimeKey] = null;
        }
      });
    },
    // 获取下拉选项筛选条件的值
    updateInputValue(data) {
      this.pageParams[data.key] = data.value;
    },
    // 改变默认下拉选项的值
    changeInput(value) {
      this.defaultSelectValue = value;
      this.$refs.dropDownFilter.inputValue = '';
      this.dropDownInputList.map((item) => {
        if (Object.keys(this.pageParams).includes(item.parameterKey)) {
          this.pageParams[item.parameterKey] = '';
        }
      });
    },
    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = [];
      if (data) {
        this.selectTableData = data;
      }
    },
    // 批量发货
    bulkShipmentBtn(value) {
      let v = this;
      let pageParamsObj = {};
      v.getSupplierWarehouseSetting(true, 'operationManage').then((val) => {
        if (val) {
          let talg = true;
          if (value === '1') {
            if (v.selectTableData.length <= 0) {
              v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005438'));
              talg = false;
            }
          } else {
            pageParamsObj = v.recordParams;
            if (v.tableData.length <= 0) {
              v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005439'));
              talg = false;
            }
          }
          // 出库单状态：待发货  订单状态：非缺货，满足这两个条件的时候才可以操作执行发货
          let orderStatusTalg = v.selectTableData.every((item) => {
            return item.orderStatus !== 7
          });
          if (!orderStatusTalg) {
            v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005465'));
            talg = false;
          } else {
            let outPackageOrderStatusTalg = v.selectTableData.every((item) => {
              return item.outPackageOrderStatus === 1
            })
            if (!outPackageOrderStatusTalg) {
              v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005466'));
              talg = false;
            }
          }
          if (talg) {
            if (value === '1') {
              pageParamsObj = {
                orderTabType: 1,
                createdEndTime: null,
                createdStartTime: null,
                outPackageOrderStatusList: [],
                packageCodes: v.selectTableData.map((item) => {
                  return item.packageCode
                }),
                packageOrderStatusList: [],
                outboundTypes: [1],
                skus: [],
                pageNum: 1,
                pageSize: 100
              }
            } else {
              pageParamsObj = v.recordParams;
            }
            v.$refs['logisticsInfo'].initLogisticsInfo(null, 'markShipment', pageParamsObj);
          }
        }
      })
    },
    // 参数处理
    handleParameter() {
      let v = this;
      let params = Object.assign({}, v.pageParams);
      params.orderTabType = Number(v.pageParams.orderTabType);
      for (let key in params) {
        if (v.filterKeyList.includes(key)) {
          params[key] = v.sepCommasFn(params[key], [',', '，', '\n']);
        }
      }
      return params;
    },
    // 选择导出数据类型  1 按选中导出 2 按查询结果导出
    changeExportBtn(value) {
      this.exportType = value;
      if (value === '1') {
        if (this.selectTableData.length <= 0) {
          this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1002232'));
          return false;
        }
      } else {
        if (this.tableData.length <= 0) {
          this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1002233'));
          return false;
        }
      }
      this.$refs['exportFields'].getExportFieldsSetting();
    },
    // 导出数据
    exportFieldsDataBtn(obj) {
      let v = this;
      let query = v.handleParameter();
      if (v.exportType === '1') {
        query.packageIds = v.selectTableData.map((item) => {
          return item.packageId;
        });
      } else {
        query.packageIds = [];
      }
      v.$refs['exportFields'].saveExportFieldsSetting().then((data) => {
        if (data) {
          v.axios.post(api.post_packageInfo_exportPackageInfoOrder, query,
            {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(res => {
            if (res.data.code === 0) {
              v.$refs['exportFields'].exportFieldsModal = false;
              let taskNumber = res.data.datas;
              setTimeout(() => {
                pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    // 批量标记到货
    batchMarkingBtn(type, data) {
      let v = this;
      let talg = true;
      if (type === 'single') {
        talg = true;
      } else {
        if (v.tableData.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005467'));
          talg = false;
        }
        if (v.selectTableData.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005468'));
          talg = false;
        }
      }
      if (talg) {
        let arrivalTalg = false;
        let newList = [];
        if (data) {
          arrivalTalg = data.orderStatus === 7;
        } else {
          newList = v.selectTableData.filter((item) => {
            return item.orderStatus === 7;
          });
          arrivalTalg = newList.length > 0;
        }
        if (arrivalTalg) {
          v.$Modal.confirm({
            title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005469'),
            content: alias1bddc4d174174d47a6dfb0437dace856.t('key1005470'),
            okText: alias1bddc4d174174d47a6dfb0437dace856.t('key1003552'),
            cancelText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000097'),
            onOk: () => {
              let query = [];
              if (type === 'single') {
                let packageInfoOrderGoodsBos = data.packageInfoOrderGoodsBos || [];
                if (packageInfoOrderGoodsBos.length > 0) {
                  packageInfoOrderGoodsBos.map((item) => {
                    query.push({
                      estimateArrivalOfGoodsTime: null,
                      markType: 3, // 标记类型 1.补货 2.停售 3.到货
                      operationType: 3, // 操作类型 1.标记缺货 2.更新标记 3.标记到货
                      outOfStockQuantity: null,
                      packageCodes: [data.packageCode],
                      productGoodsId: item.productGoodsId
                    })
                  })
                }
              } else {
                let packageInfoOrderGoodsBosList = [];
                v.selectTableData.map((item) => {
                  packageInfoOrderGoodsBosList.push(...item.packageInfoOrderGoodsBos)
                });
                if (packageInfoOrderGoodsBosList.length > 0) {
                  packageInfoOrderGoodsBosList.map((item) => {
                    query.push({
                      estimateArrivalOfGoodsTime: null,
                      markType: 3, // 标记类型 1.补货 2.停售 3.到货
                      operationType: 3, // 操作类型 1.标记缺货 2.更新标记 3.标记到货
                      outOfStockQuantity: null,
                      packageCodes: [item.packageCode],
                      productGoodsId: item.productGoodsId
                    })
                  })
                }
              }
              v.axios.post(api.post_packageInfo_markOutStock, query, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(res => {
                if (res.data.code === 0) {
                  v.$refs['tableDom'].selectAll(false);
                  v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000358'));
                  v.pageParamsStatus = true;
                }
              });
            }
          });
        } else {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005471'));
          return false;
        }
      }
    },
    // 打印YMS条码
    printBarcodeBtn(value) {
      let v = this;
      let talg = true;
      if (value === '1') {
        this.$refs['scanOrderChange'].scanOrderChangeModal = true;
        this.$refs['scanOrderChange'].scanOrderType = 'supplierOrderList';
      } else {
        if (v.selectTableData.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005443'));
          talg = false;
        }
        if (v.tableData.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005444'));
          talg = false;
        }
        if (talg) {
          v.handleOrderBarcode('all');
        }
      }
    },
    // 批量打印订单条码
    handleOrderBarcode(type, packageId) {
      let v = this;
      let packageIds = [];
      if (type === 'all') {
        v.selectTableData.map((item) => {
          packageIds.push({packageId: item.packageId});
        })
      } else {
        packageIds = [{packageId: packageId}];
      }
      v.axios.post(api.post_packageInfo_appendPackageInfoOrderExpressBill, packageIds, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(res => {
        if (res.data.code === 0) {
          v.updateSelect();
          let data = res.data.datas;
          if (data) {
            v.commonPrint(data, '1');
          }
        }
      });
    },
    // 打印配货清单
    printDistributionBtn() {
      let talg = this.tableData.length > 0 && this.selectTableData.length > 0;
      if (talg) {
        this.$refs['distributionListFormat'].initDistributionListFormat(this.selectTableData, 'supplierOrderList');
      } else {
        this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005442'));
        return false;
      }
    },
    // 重置勾选数据的状态
    updateSelect() {
      this.$refs['tableDom'].selectAll(false);
      this.selectTableData = [];
    },
    // 处理列表字段
    handleColumns(data) {
      let v = this;
      v.optionBtnWidth = [];
      // 0全部  1:待发货 2:已发货 4:已完成 5:已取消 6待接单 7待客户付款
      let hideKeyColumns = [];
      let obj = {
        '1': ['settlementStatus', 'despatchTime', 'ymsPackageStatusTime', 'actualSortingNumber'],
        '2': ['settlementStatus', 'ymsPackageStatusTime', 'remainingDeliveryTime', 'actualSortingNumber'],
        '4': ['remainingDeliveryTime'],
        '5': ['settlementStatus', 'remainingDeliveryTime', 'actualSortingNumber'],
        '6': ['actualSortingNumber'],
        '7': ['actualSortingNumber'],
      }
      hideKeyColumns = obj[v.pageParams.orderTabType] || [];
      let sortKeyList = data || v.setColumnsList;
      let list = v.handleCustomColumnsSort(sortKeyList, hideKeyColumns, v.originalColumns, v.defaultSelectKeyList);
      if (list.length > 0) {
        v.tableColumns = list;
      } else {
        v.tableColumns = v.originalColumns.filter((item) => {
          return !hideKeyColumns.includes(item.key)
        });
      }
      v.updateTableDom('tableDom');
    },
    // 操作批量发货之后，打印箱唛
    printBoxBtn(data) {
      this.$refs['printTalg'].getPrintData(data.wmsPickupOrderId);
      this.getPendingProcessingNum();
    },
    // 切换Tab
    changeTab(name) {
      let v = this;
      v.$refs['pageParams'].resetFields();
      v.changeInput(1);
      v.changeTimeInput(1);
      v.selectTableData = [];
      v.pageParams.orderTabType = name;
      if (['0', '4', '5'].includes(v.pageParams.orderTabType)) {
        v.setDefaultTimerParams('three_months', 1);
      }
      v.pageParams.pageNum = 1;
      v.pageParamsStatus = true;
    },
    // 获取当前列表设置展示的字段
    customColumnsData(data) {
      this.setColumnsList = data;
      this.handleColumns(data);
    },
    // 标记缺货
    markOutStockBtn(type, packageId, val) {
      let v = this;
      let packageIdList = [];
      if (type === 'single') {
        packageIdList = [packageId]
      } else {
        if (v.selectTableData.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005468'));
          return false;
        } else {
          let talg = v.selectTableData.some((item) => {
            return item.orderStatus == 7
          });
          if (talg) {
            v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005472'));
            return false;
          } else {
            v.selectTableData.map((item) => {
              packageIdList.push(item.packageId)
            })
          }
        }
      }
      if (packageIdList.length > 0) {
        v.axios.post(api.post_packageInfo_queryOvertimeUnsentList, packageIdList).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              let newData = data.slice(0, 100);
              v.$refs['overtimeOrderProcess'].initOvertimeOrderProcessingData('mark', newData, val)
            } else {
              v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1003563'));
              return false;
            }
          }
        });
      }
    },
    // 处理状态数据
    handleStatusData(value, val) {
      // 出库状态
      this.deliveryOrderStatusList = [];
      let isWarehouseManagement = val || this.isWarehouseManagement;
      let title = isWarehouseManagement === 'Y' ? alias1bddc4d174174d47a6dfb0437dace856.t('key1001156') : alias1bddc4d174174d47a6dfb0437dace856.t('key1001182');
      let statusList = [
        {value: 1, title: title},
        {value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001160')},
        {value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001276')},
        {value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001146')},
        {value: 5, title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1001155')}`},
        {value: 6, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001277')},
        {value: 7, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001173')},
        {value: 8, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001168')},
      ];
      let values = {
        '0': [1, 2, 3, 4, 5, 6, 7, 8],
        '1': [1, 2, 3, 6],
        '2': [4, 8],
        '4': [4, 5],
        '5': [7]
      }
      if (values[value]) {
        statusList.map((item) => {
          if (values[value].includes(item.value)) {
            this.deliveryOrderStatusList.push(item);
          }
        })
      }
      // 订单状态
      this.orderStatusList = [];
      let orderValues = {
        '0': [0, 1, 2, 3, 4, 5, 6, 7],
        '1': [1, 6, 7, 3]
      }
      if (orderValues[value]) {
        this.orderStatusData.map((item) => {
          if (orderValues[value].includes(item.value)) {
            this.orderStatusList.push(item);
          }
        })
      }
    },
    // 获取待处理的数量
    getPendingProcessingNum() {
      let v = this;
      let orderTabType = JSON.parse(JSON.stringify(v.pageParams.orderTabType));
      v.axios.get(api.get_packageInfo_queryPackageInfoOrderTabTotal).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            v.$store.commit('updatePendingCount', Date.now());
            v.pageParams.orderTabType = '0';
            v.pendingOrdersTotal = data.pendingOrderTotal || 0;
            v.toBeShippedTotal = data.toBeShippedTotal || 0;
            v.$nextTick(() => {
              v.pageParams.orderTabType = orderTabType;
            })
          } else {
            v.pendingOrdersTotal = 0;
            v.toBeShippedTotal = 0;
          }
        }
      });
    },
    // 接单、拒绝
    takingOrdersBtn(type, val, packageId) {
      let v = this;
      let talg = true;
      let query = {
        isOrderTaking: val, // 1:接单 2:拒绝接单
        packageIds: []
      }
      if (type === 'all') {
        if (v.tableData.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005439'));
          talg = false;
        } else {
          if (v.selectTableData.length <= 0) {
            v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005438'));
            talg = false;
          } else {
            query.packageIds = this.selectTableData.map((item) => {
              return item.packageId
            });
          }
        }
      } else {
        query.packageIds = [packageId];
      }
      if (talg) {
        let title = val === 1 ? alias1bddc4d174174d47a6dfb0437dace856.t('key1005473') : alias1bddc4d174174d47a6dfb0437dace856.t('key1005474');
        let content = val === 1 ? alias1bddc4d174174d47a6dfb0437dace856.t('key1005475') :
          alias1bddc4d174174d47a6dfb0437dace856.t('key1005476');
        let okText = val === 1 ? alias1bddc4d174174d47a6dfb0437dace856.t('key1004980') : alias1bddc4d174174d47a6dfb0437dace856.t('key1005477');
        let text = val === 1 ? alias1bddc4d174174d47a6dfb0437dace856.t('key1005478') : alias1bddc4d174174d47a6dfb0437dace856.t('key1005479');
        v.$Modal.confirm({
          title: title,
          content: content,
          okText: okText,
          cancelText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000097'),
          onOk: () => {
            v.axios.put(api.put_packageInfo_receivingOrders, query, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(response => {
              if (response.data.code === 0) {
                v.$Message.success(text);
                v.pageParamsStatus = true;
              }
            });
          },
          onCancel: () => {
          }
        });
      }
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    },
    // 监听tab栏切换
    'pageParams.orderTabType': {
      handler(value) {
        this.handleStatusData(value)
      },
      immediate: true,
      deep: true
    },
    isWarehouseManagement: {
      handler(val) {
        this.handleStatusData(this.pageParams.orderTabType, val);
      },
      deep: true
    }
  },
  components: {
    dropDownFilter,
    exportFieldsModal,
    distributionListFormatModal,
    dailyRecordModal,
    scanOrderChangeModal,
    logisticsInfoModal,
    printCaseMarkModal,
    dropDownDateTime,
    customColumnsDrawer,
    overtimeOrderProcessingModal
  }
}
</script>

<style lang="less" scoped>
/deep/ .table_box {
  .selection_styles_box {
    position: sticky !important;
    left: 0;
    z-index: 5;
    overflow: initial !important;

    &:after {
      right: -2px;
      top: 0;
      content: ' ';
      width: 2px;
      height: 100%;
      background: #e8eaec;
      position: absolute;
      z-index: 100;
    }
  }

  td.options_styles_box {
    position: sticky !important;
    right: 0px;
    z-index: 5;
    overflow: initial !important;

    &:before {
      left: -2px;
      top: 0;
      content: ' ';
      width: 2px;
      height: 100%;
      background: #e8eaec;
      position: absolute;
      z-index: 100;
    }

    &:after {
      right: -1px;
      top: 0;
      content: ' ';
      width: 2px;
      height: 100%;
      background: #fff;
      position: absolute;
      z-index: 100;
    }

    .ivu-table-cell {
      padding-top: 8px;
      display: flex;
      align-items: flex-start;
      height: 100%;
    }
  }

  th.options_styles_box {
    position: sticky !important;
    right: 0px;
    z-index: 5;
    overflow: initial !important;

    &:before {
      left: -2px;
      top: 0;
      content: ' ';
      width: 2px;
      height: 100%;
      background: #e8eaec;
      position: absolute;
      z-index: 100;
    }
  }

  th.options_styles_box:nth-last-child(2) {
    position: sticky !important;
    right: 12px;
    z-index: 5;
    overflow: initial !important;

    &:before {
      left: -2px;
      top: 0;
      content: ' ';
      width: 2px;
      height: 100%;
      background: #e8eaec;
      position: absolute;
      z-index: 100;
    }

    &:after {
      right: -12px;
      top: 0;
      content: ' ';
      width: 14px;
      height: 100%;
      background: #F8F8F9;
      position: absolute;
      z-index: 100;
    }
  }
}

</style>